import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'helpers/utilities';
import { EVENT, breakpoints } from 'config/constants';
import EventHandler from 'helpers/EventHandler';
import { TimelineMax, TweenMax, Power4, Power2 } from 'gsap';
import { polyfillDOMImplementation } from 'interweave-ssr';
import CarouselVideo from 'components/CarouselVideo';
import { stripParagraphTag, isolateStrongPerWord } from 'helpers/text';
import { pickArticleHeaderContent } from 'queries/articleHeader';
import useDebounce from '../../../hooks/useDebounce';
import SplitText from '../../../helpers/SplitText';
import { killChildTweensOf } from '../../../helpers/gsapUtils';

import styles from './styles.scss';

const TRANSITION_SPEED = 0.25; // in seconds.

const INACTIVE_WIDTH = {
  small: 224,
  large: 216
};

const imageSizes = {
  largeDesktop: 2000,
  smallDesktop: 1440,
  tablet: 1248,
  mobile: 960
};

const CarouselHeroDesktop = props => {
  const { data } = props;

  const debounce = useDebounce();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const slides = useRef([]);
  const middleSlide = useRef(null);
  const middleSlideBg = useRef(null);
  const slideDescription = useRef(null);
  const slideDescriptHeight = useRef([]);
  const textTLsEnter = useRef([]);
  const inactiveWidth = useRef(null);
  const activeWidth = useRef(null);
  const splitText = useRef({});

  /**
   * Gets correct video data based on data parameter.
   * @param {obj} data
   *
   * @return video
   */
  const getVideo = useCallback(data => {
    const videoType = get(data, 'videoType');
    const video =
      videoType === 'embedVideos'
        ? get(data, 'videoBackground')
        : get(data, 'videoAssetBackground[0]');

    return video;
  }, []);

  /**
   * Based on previous index and current index set left/right position for the middle slide and its background.
   * @param {number} prevIndex
   * @param {number} currIndex
   */
  const setCenterSlideAnchor = useCallback((prevIndex, currIndex) => {
    const { current: _middleSlideBg } = middleSlideBg;
    const { current: _middleSlide } = middleSlide;

    if (!_middleSlide || !_middleSlideBg) return;

    if (
      (prevIndex === 0 && currIndex === 1) ||
      (prevIndex === 1 && currIndex === 0) ||
      (prevIndex === 2 && currIndex === 0)
    ) {
      // set anchors to the right.
      _middleSlideBg.style.right = 0;
      _middleSlideBg.style.left = 'auto';
      _middleSlide.style.right = `${inactiveWidth.current}px`;
      _middleSlide.style.left = 'auto';
    } else if ((prevIndex === 1 && currIndex === 2) || (prevIndex === 2 && currIndex === 1)) {
      // set anchors to the left.
      _middleSlideBg.style.right = 'auto';
      _middleSlideBg.style.left = 0;
      _middleSlide.style.right = 'auto';
      _middleSlide.style.left = `${inactiveWidth.current}px`;
    } else if (currIndex === 2) {
      // set anchors to left but w/ a tween.
      _middleSlide.style.right = 'auto';
      _middleSlideBg.style.right = 'auto';
      _middleSlideBg.style.left = 0;
      TweenMax.fromTo(
        '#gsap-slide-1',
        TRANSITION_SPEED,
        {
          left: `${activeWidth.current}px`
        },
        {
          left: `${inactiveWidth.current}px`
        }
      );
    }
  }, []);

  /**
   * Stores active and inactive slide widths for use in Timeline and resize events.
   */
  const storeWidths = useCallback(() => {
    inactiveWidth.current =
      window.innerWidth >= breakpoints.largeDesktop ? INACTIVE_WIDTH.large : INACTIVE_WIDTH.small;
    activeWidth.current = window.innerWidth - inactiveWidth.current * 2;
  }, []);

  /**
   * Sets up split text. Adjust bolded elements so they break naturally -- if there is bolded text.
   * @param {number} index
   */
  const setDescriptionText = useCallback(index => {
    if (splitText.current[index]) {
      splitText.current[index].revert();
      splitText.current[index].split();
    } else {
      splitText.current[index] = new SplitText(`#gsap-description-${index}`, {
        type: 'lines',
        linesClass: `${styles['carousel-hero-desktop__project-description__line']}`
      });
    }
  }, []);

  /**
   * Creates a gsap timeline for each slide. They are played based on which slide is hovered and which was just the activeSlide.
   * The timelines a stored in an array so we can destroy them on resize.
   * Fire the timeline associated with the activeSlide.
   */
  const createTextTimelines = useCallback(() => {
    textTLsEnter.current = [];

    Array.from(data).forEach((slide, index) => {
      const tl = new TimelineMax({ paused: true, ease: Power2.ease });
      setDescriptionText(index);

      tl.fromTo(
        `#gsap-slide-${index}`,
        TRANSITION_SPEED,
        {
          width: inactiveWidth.current,
          force3D: true
        },
        {
          width: activeWidth.current,
          force3D: true
        },
        'enter'
      );
      tl.fromTo(
        `#gsap-slide-${index} img`,
        TRANSITION_SPEED,
        {
          '-webkit-filter': 'grayscale(100%)',
          filter: 'grayscale(100%)',
          force3D: true
        },
        {
          '-webkit-filter': 'grayscale(0%)',
          filter: 'grayscale(0%)',
          force3D: true
        },
        'enter'
      );
      tl.fromTo(
        `#gsap-slide-overlay-${index}`,
        TRANSITION_SPEED,
        {
          backgroundColor: 'rgba(0, 0, 0, 0.6)'
        },
        {
          backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        'enter'
      );
      tl.fromTo(
        `#gsap-eyebrow-${index}`,
        TRANSITION_SPEED,
        {
          transform: `translateY(${slideDescriptHeight.current[index]}px)`
        },
        {
          transform: 'translateY(0)',
          ease: Power4.easeOut
        },
        'enter'
      );
      tl.fromTo(
        `#gsap-description-${index}`,
        TRANSITION_SPEED,
        {
          opacity: 0,
          force3D: true
        },
        {
          opacity: 1,
          force3D: true,
          onStart: () => {
            TweenMax.staggerFrom(
              `#gsap-description-${index} div`,
              TRANSITION_SPEED,
              {
                paddingTop: 43
              },
              TRANSITION_SPEED / 3
            );
            // Update: Removed `-=${(TRANSITION_SPEED / 3) * 0.5}` causing "onCompleteAll.apply is not a function".
            // TweenMax.staggerFrom() does not implement the 5th parameter "position parameter".
          }
        },
        'enter'
      );
      tl.fromTo(
        `#gsap-content-${index} > a`,
        TRANSITION_SPEED,
        {
          'padding-left': '32px'
        },
        {
          'padding-left': '64px'
        },
        'enter'
      );

      textTLsEnter.current.push(tl);
    });

    if (!isEmpty(textTLsEnter.current[activeSlideIndex])) {
      // Set initial slide to end of onMouseEnter timeline.
      textTLsEnter.current[activeSlideIndex].progress(1, false);
    }
  }, [setDescriptionText, activeSlideIndex]);

  /**
   * Kills all stored timelines and tweens that are children of slides.
   */
  const resetTLs = useCallback(() => {
    textTLsEnter.current.forEach(tl => {
      const tlChildren = tl.getChildren();
      tl.kill();
      tlChildren.forEach(child => {
        if (child.target) {
          TweenMax.set(child.target, { clearProps: 'all' });
        }
      });
    });

    textTLsEnter.current = [];

    slides.current.forEach(slide => {
      killChildTweensOf(slide);
      TweenMax.set(slide, { clearProps: 'all' });
    });

    createTextTimelines();
  }, [createTextTimelines]);

  /**
   * Assigns updated activeSlideIndex and fires timelines based on previous and new index.
   * @param {number} newIndex
   */
  const handleMouseEnter = useCallback(
    newIndex => {
      if (!middleSlide.current || !middleSlideBg.current) return;

      if (newIndex !== activeSlideIndex) {
        const oldIndex = activeSlideIndex;

        if (textTLsEnter.current[newIndex]) {
          textTLsEnter.current[newIndex].play();
        }

        if (textTLsEnter.current[activeSlideIndex]) {
          textTLsEnter.current[activeSlideIndex].reverse();
        }

        setActiveSlideIndex(newIndex);
        setCenterSlideAnchor(oldIndex, newIndex);
      }
    },
    [setCenterSlideAnchor, activeSlideIndex]
  );

  // componentDidMount
  useEffect(() => {
    polyfillDOMImplementation();

    if (data.length > 1) {
      slides.current = document.querySelectorAll('.js-slides');
      middleSlide.current = document.getElementById('gsap-slide-1');
      middleSlideBg.current = document.getElementById('gsap-slide-bg-1');
      slideDescription.current = document.getElementById('gsap-description-0');
      slideDescriptHeight.current = [];

      for (let i = 0; i < data.length; i++) {
        slideDescriptHeight.current[i] = document.getElementById(
          `gsap-description-${i}`
        ).clientHeight;
      }

      storeWidths();
      createTextTimelines();
    }
  }, []);

  useEffect(() => {
    /**
     * On resize, update stored widths and reset timelines.
     * Then based on activeSlideIndex apply new activWidth to the activeSlide.
     */
    const handleResize = () => {
      storeWidths();
      resetTLs();

      // Update active item's width.
      slides.current.forEach((slide, index) => {
        if (index === activeSlideIndex) {
          slides.current[index].style.width = `${activeWidth.current}px`;
        }
      });

      if (activeSlideIndex > 0) {
        // Adjust the position of slider elements after resize.
        setCenterSlideAnchor(activeSlideIndex - 1, activeSlideIndex);
      }
    };
    const resizeEvent = EventHandler.subscribe(EVENT.RESIZE, debounce(handleResize, 500));
    return () => EventHandler.unsubscribe(resizeEvent);
  }, [storeWidths, resetTLs, setCenterSlideAnchor, activeSlideIndex]);

  return (
    <section
      className={`${styles['carousel-hero-desktop__container']} ${
        data.length === 1 ? `${styles['carousel-hero-desktop__container--single']}` : ''
      }`}>
      {Array.from(data).map((slide, index) => (
        <div
          key={index}
          onMouseEnter={() => handleMouseEnter(index)}
          id={`gsap-slide-${index}`}
          className={`${styles['carousel-hero-desktop__slide']} js-slides`}>
          <div
            id={`gsap-slide-bg-${index}`}
            className={styles['carousel-hero-desktop__background-container']}>
            <CarouselVideo
              index={index}
              isActive={index === activeSlideIndex}
              imageURL={get(slide, 'background[0].url')}
              imageAlt={
                get(slide, 'background[0].assetAltText')
                  ? get(slide, 'background[0].assetAltText')
                  : get(slide, 'background[0].title')
              }
              videoURL={get(getVideo(slide), 'url')}
              videoClass={styles['carousel-hero-desktop__background']}
              imageClass={styles['carousel-hero-desktop__background']}
              imageSizes={imageSizes}
            />
            <div
              id={`gsap-slide-overlay-${index}`}
              className={styles['carousel-hero-desktop__slide-overlay']}
            />
          </div>
          <div
            id={`gsap-content-${index}`}
            className={`${styles['carousel-hero-desktop__content-container']}`}>
            <Link
              to={{ pathname: `/${get(slide, 'project[0].uri', '#')}` }}
              onFocus={() => handleMouseEnter(index)}>
              <span
                id={`gsap-eyebrow-${index}`}
                className={styles['carousel-hero-desktop__eyebrow']}
                dangerouslySetInnerHTML={{
                  __html: stripParagraphTag(get(slide, 'clientLabel.content'))
                }}
              />
              <h3
                id={`gsap-description-${index}`}
                className={styles['carousel-hero-desktop__project-description']}
                dangerouslySetInnerHTML={{
                  __html: isolateStrongPerWord(
                    stripParagraphTag(pickArticleHeaderContent(get(slide, 'project[0]')))
                  )
                }}
              />
            </Link>
          </div>
        </div>
      ))}
    </section>
  );
};

CarouselHeroDesktop.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
};

export default CarouselHeroDesktop;
