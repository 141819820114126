import React, { useState, useRef } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import styles from './styles.scss';

const Accordion = ({
  children,
  className = '',
  active = true,
  show,
  height: definedHeight,
  heightTo = '0px'
}) => {
  const [height, setHeight] = useState('auto');
  const containerRef = useRef();

  const style = active
    ? { height: show ? definedHeight || height : heightTo, overflow: show ? '' : 'hidden' }
    : {};
  return (
    <div style={style} className={`${styles.accordion} ${className}`}>
      {!definedHeight ? (
        <div ref={containerRef}>
          <ReactResizeDetector
            targetDomEl={containerRef.current}
            handleHeight
            handleWidth
            onResize={(_, containerHeight) => {
              if (parseInt(height, 10) !== containerHeight) {
                setHeight(`${containerHeight}px`);
              }
            }}
          />
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default Accordion;
