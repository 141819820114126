import React from 'react';
import { get } from 'helpers/utilities';
import classNames from 'classnames/bind';
import { checkMediaType } from 'helpers/media';
import Image from 'components/Image';
import Video from 'components/Video';

import styles from './styles.scss';
import useGlobalBackground from '../../hooks/useGlobalBackground';

const cx = classNames.bind(styles);

const FixedBackground = () => {
  const globalBackground = useGlobalBackground();
  const mainStyles = cx('fixedBackground', 'fixedBackground--active');

  const renderBackgrounds = bgArr =>
    bgArr.map((bg, i) => {
      const asset = get(bg, 'tagBackground[0]');
      const vimeoAsset = get(bg, 'tagVideoBackground');
      const pageBackgroundType = checkMediaType(get(asset, 'extension'));
      const itemStyles = cx('fixedBackground__item', {
        'fixedBackground__item--active': i + 1 === globalBackground.currentIndex,
        'fixedBackground__item--single': globalBackground.assets.length === 1
      });

      const renderAsset = () => {
        if (vimeoAsset) {
          return (
            <Video
              src={get(vimeoAsset, 'url')}
              muted={true}
              autoPlay={true}
              loop={true}
              controls={false}
            />
          );
        }

        if (pageBackgroundType === 'image') {
          return <Image src={get(asset, 'url', '')} alt={get(asset, 'title')} />;
        }

        if (pageBackgroundType === 'video') {
          return (
            <Video
              autoPlay={true}
              loop={true}
              controls={false}
              muted={true}
              mp4={get(asset, 'extension') === 'mp4' ? get(asset, 'url') : false}
              webm={get(asset, 'extension') === 'webm' ? get(asset, 'url') : false}
            />
          );
        }

        return null;
      };

      return (
        <li key={i} className={itemStyles}>
          {renderAsset()}
        </li>
      );
    });

  return (
    <div className={`${mainStyles} fixedBackground`}>
      <ul className={styles.fixedBackground__list}>{renderBackgrounds(globalBackground.assets)}</ul>
    </div>
  );
};

export default FixedBackground;
