import React, { Fragment } from 'react';

// components
import CardGrid from 'components/CardGrid';
import CarouselHero from 'components/CarouselHero';
import Features from 'components/Features';
import Contact from 'components/Contact';
import StickyFilter, { StickyFilterProvider } from 'components/StickyFilter';
import ExpandedFilter from 'components/ExpandedFilter';
import NavigationThemeSetter from 'components/NavigationThemeSetter';
import withHOCS from 'components/withHOCs';
import withStickyFilterSetter from 'components/withStickyFilterSetter';
import ScrollDepthTracker from 'components/ScrollDepthTracker';
import Meta from 'components/Meta';

// helpers
import { get } from 'helpers/utilities';
import buildGTMData from 'helpers/buildGTMData';

// queries
import theThingsWeMake from 'queries/theThingsWeMake';

// hooks
import useThingsWeMakeContent from '../../hooks/useThingsWeMakeContent';
import useAllWorks from '../../hooks/useAllWorks';
import useContact from '../../hooks/useContact';

const FeaturesWithStickyFilter = withStickyFilterSetter({
  componentName: 'features',
  color: 'white'
})(Features);
const CardGridWithStickyFilter = withStickyFilterSetter({
  componentName: 'cardgrid',
  color: 'black'
})(CardGrid);

const AllWorksCardGrid = () => {
  const allWorks = useAllWorks();

  return (
    allWorks && (
      <CardGridWithStickyFilter data={allWorks} cardGridTitle="<strong>All</strong> Work" />
    )
  );
};

const ThingsWeMake = ({ active, transporterHeadRef }) => {
  const thingsWeMakeContent = useThingsWeMakeContent();
  const gtmData = buildGTMData(thingsWeMakeContent);
  const contactData = useContact(thingsWeMakeContent);

  if (!thingsWeMakeContent) return null;

  const { carouselHero, featuresModule = [] } = thingsWeMakeContent;
  const metaDetails = {
    metaSection: get(thingsWeMakeContent, 'socialMetaSection[0]'),
    entryTitle: get(thingsWeMakeContent, 'title'),
    placeholderImage: get(thingsWeMakeContent, 'carouselHero[0]background[0].url')
  };

  return (
    <>
      <Meta metaDetails={metaDetails} active={active} gtm={gtmData} />
      <ScrollDepthTracker
        pageName="ThingsWeMake"
        pageURL={`${window.location.host}/things-we-make`}>
        <NavigationThemeSetter navTheme="header" active={active}>
          <CarouselHero data={carouselHero} />
        </NavigationThemeSetter>
        <StickyFilter title="" transporterHeadRef={transporterHeadRef} />
        <NavigationThemeSetter navTheme="white">
          <FeaturesWithStickyFilter
            features={featuresModule}
            componentQuery={theThingsWeMake.component}
          />
          <ExpandedFilter />
        </NavigationThemeSetter>
        <NavigationThemeSetter navTheme="black">
          <AllWorksCardGrid />
        </NavigationThemeSetter>
        <NavigationThemeSetter navTheme="white">
          <Contact {...contactData} />
        </NavigationThemeSetter>
      </ScrollDepthTracker>
    </>
  );
};

export default withHOCS(StickyFilterProvider)(ThingsWeMake);
