import React, { useState, useEffect, useRef } from 'react';
import { get, isEmpty } from 'helpers/utilities';
import { TimelineMax, TweenMax, Power4 } from 'gsap';
import { polyfillDOMImplementation } from 'interweave-ssr';
import { Link } from 'react-router-dom';
import CarouselVideo from 'components/CarouselVideo';
import Icon from 'components/Icon';
import Swipe from 'react-easy-swipe';
import { stripParagraphTag, isolateStrongPerWord } from 'helpers/text';
import { pickArticleHeaderContent } from 'queries/articleHeader';
import { EVENT, breakpoints } from 'config/constants';
import EventHandler from 'helpers/EventHandler';
import SplitText from '../../../helpers/SplitText';
import useDebounce from '../../../hooks/useDebounce';

import styles from './styles.scss';

const TRANSITION_SPEED = 0.5; // in seconds.

const CarouselHeroMobile = props => {
  const { data } = props;
  const carouselMobileRef = useRef();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const debounce = useDebounce();
  // Refs
  const textTLsEnter = useRef([]);
  const slideDescriptionRef = useRef();
  const slideDescriptHeight = useRef();
  const splitText = useRef({});

  polyfillDOMImplementation();

  // componentDidMount
  useEffect(() => {
    if (carouselMobileRef.current) {
      carouselMobileRef.current.style.height =
        window.innerWidth < breakpoints.tablet ? `${window.innerHeight}px` : '';
    }

    slideDescriptionRef.current = document.getElementById('gsap-description-0');
    slideDescriptHeight.current = slideDescriptionRef.current.clientHeight;

    createTextTimelines();

    const resizeRef = EventHandler.subscribe(
      EVENT.RESIZE,
      debounce(() => {
        if (carouselMobileRef.current && window.innerWidth >= breakpoints.tablet) {
          carouselMobileRef.current.style.height = '';
        }
      }, 500)
    );

    // Cleanup
    return () => EventHandler.unsubscribe(resizeRef);
  }, []);

  function createTextTimelines() {
    Array.from(data).forEach((slide, index) => {
      const tlEnter = new TimelineMax({ paused: true });
      setDescriptionText(index);

      tlEnter.fromTo(
        `#gsap-eyebrow-${index}`,
        TRANSITION_SPEED,
        {
          transform: `translateY(${slideDescriptHeight}px)`
        },
        {
          transform: 'translateY(0)',
          ease: Power4.easeOut
        },
        'enter'
      );
      tlEnter.fromTo(
        `#gsap-description-${index}`,
        TRANSITION_SPEED,
        {
          opacity: 0
        },
        {
          opacity: 1,
          onStart: () => {
            TweenMax.staggerFrom(
              `#gsap-description-${index} div`,
              TRANSITION_SPEED,
              {
                paddingTop: 43
              },
              TRANSITION_SPEED / 3
            );
            // Update: Removed `-=${(TRANSITION_SPEED / 3) * 0.5}` causing "onCompleteAll.apply is not a function".
            // TweenMax.staggerFrom() does not implement the 5th parameter "position parameter".
          }
        },
        'enter'
      );

      textTLsEnter.current.push(tlEnter);
    });

    if (!isEmpty(textTLsEnter.current[0])) {
      // Set initial slide to end of onMouseEnter timeline.
      textTLsEnter.current[0].progress(1, false);
    }
  }

  /**
   * Gets correct video data based on data parameter.
   * @param {obj} data
   *
   * @return video
   */
  function getVideo(data) {
    const videoType = get(data, 'videoType');
    const video =
      videoType === 'embedVideos'
        ? get(data, 'videoBackground')
        : get(data, 'videoAssetBackground[0]');

    return video;
  }

  function switchSlide(newIndex) {
    const oldIndex = activeSlideIndex;

    // If last slide, go to first
    let updatedIndex = newIndex === data.length ? 0 : newIndex;

    // If first slide, have last slide before it
    updatedIndex = newIndex === -1 ? data.length - 1 : updatedIndex;

    if (newIndex !== oldIndex) {
      textTLsEnter.current[updatedIndex].play();
      textTLsEnter.current[oldIndex].reverse();
      setActiveSlideIndex(updatedIndex);
    }
  }

  /**
   * Sets up split text. Adjust bolded elements so they break naturally -- if there is bolded text.
   * @param {number} index
   */
  function setDescriptionText(index) {
    if (splitText.current[index]) splitText.current[index].revert();
    splitText.current[index] = new SplitText(`#gsap-description-${index}`, {
      type: 'lines',
      linesClass: `${styles['carousel-hero-mobile__project-description__line']}`
    });
  }

  return (
    <section
      ref={carouselMobileRef}
      className={`${styles['carousel-hero-mobile__container']} ${
        data.length === 1 && styles['carousel-hero-mobile__slide--single']
      } ${styles[`carousel-hero-mobile__container--active-${activeSlideIndex}`]}`}>
      {data.length > 1 && (
        <div
          className={styles['carousel-hero-mobile__arrow']}
          onClick={() => switchSlide(activeSlideIndex + 1)}>
          <Icon name="chevronThickDown" size="large" color="white" />
        </div>
      )}

      <Swipe
        className={`${styles['carousel-hero-mobile__swipe-wrapper']}`}
        onSwipeLeft={() => switchSlide(activeSlideIndex + 1)}
        onSwipeRight={() => switchSlide(activeSlideIndex - 1)}
        tolerance={90}>
        {Array.from(data).map((slide, index) => (
          <div
            key={index}
            onClick={() => switchSlide(index)}
            className={`${styles['carousel-hero-mobile__slide']} ${
              index === activeSlideIndex && styles['carousel-hero-mobile__slide--active']
            } ${
              ((index === 0 && activeSlideIndex === data.length - 1) ||
                index === activeSlideIndex + 1) &&
              styles['carousel-hero-mobile__slide--next']
            } ${
              index === activeSlideIndex - 1 ||
              (activeSlideIndex === 0 &&
                index === data.length - 1 &&
                styles['carousel-hero-mobile__slide--last'])
            }`}>
            <div
              id={`gsap-slide-bg-${index}`}
              className={styles['carousel-hero-mobile__background-container']}>
              <CarouselVideo
                imageURL={get(slide, 'background[0].url')}
                imageAlt=" "
                videoURL={get(getVideo(slide), 'url')}
                videoClass={styles['carousel-hero-mobile__background']}
                imageClass={styles['carousel-hero-mobile__background']}
              />
              <div className={styles['carousel-hero-mobile__slide-overlay']} />
            </div>
            <div
              id={`gsap-content-${index}`}
              className={`${styles['carousel-hero-mobile__content-container']}`}>
              <Link to={{ pathname: `/${get(slide, 'project[0].uri', '#')}` }}>
                <span
                  id={`gsap-eyebrow-${index}`}
                  className={styles['carousel-hero-mobile__eyebrow']}
                  dangerouslySetInnerHTML={{
                    __html: stripParagraphTag(get(slide, 'clientLabel.content'))
                  }}
                />
                <h3
                  id={`gsap-description-${index}`}
                  className={styles['carousel-hero-mobile__project-description']}
                  dangerouslySetInnerHTML={{
                    __html: isolateStrongPerWord(
                      stripParagraphTag(pickArticleHeaderContent(get(slide, 'project[0]')))
                    )
                  }}
                />
              </Link>
            </div>
          </div>
        ))}
      </Swipe>

      {data.length > 1 && (
        <div className={styles['carousel-hero-mobile__progress-wrapper']}>
          {Array.from(data).map((slide, index) => (
            <div
              key={index}
              onClick={() => switchSlide(index)}
              id={`progress-slide-${index}`}
              className={`${styles['carousel-hero-mobile__progress-bar']} ${
                index === activeSlideIndex && styles['carousel-hero-mobile__progress-bar--active']
              }`}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default CarouselHeroMobile;
