import React from 'react';

// components
import Accordion from 'components/Accordion';
import SpecialCardGrid from 'components/SpecialCardGrid';
import AwardsModule from 'components/AwardsModule';

// helpers
import { get } from 'helpers/utilities';
import { flipProp } from 'helpers/fp';

// hooks
import useBreakpoint from '../../hooks/useBreakpoint';

import styles from './styles.scss';

const getCollectionNameClass = flipProp({
  simplified: styles.name_simplified,
  specialized1: styles.name,
  specialized2: styles.name
});

const features = {
  specialized1: [1, 4, 5],
  specialized2: [1, 4, 8],
  simplified: []
};

const CardGridCollection = ({
  className = '',
  cardGridCollectionRef,
  awardsModule,
  collectionEntries = [],
  startYear,
  endYear,
  hideYear,
  rangeType,
  active,
  show,
  onCollectionNameClick,
  type = 'simplified' // either specialized1, specialized2, simplified
}) => {
  const breakpoint = useBreakpoint();
  const awards = get(awardsModule, '[0].awards', awardsModule);
  const ariaAttribute = breakpoint === 'mobile' ? { role: 'button' } : {};
  // Add modifier class if year is hidden to add top padding.
  const collectionModifierClass = hideYear ? styles['card_grid_collection--no-year'] : '';

  return (
    <section
      className={`${styles.card_grid_collection} ${className} ${collectionModifierClass}`}
      ref={cardGridCollectionRef}>
      {!hideYear && (
        <header
          className={`${getCollectionNameClass(type)} ${
            rangeType !== 'bestof' ? styles.name_hide : ''
          }`}
          {...ariaAttribute}
          onClick={onCollectionNameClick}
          tabIndex={!active ? '-1' : ''}>
          <span>
            {rangeType === 'bestof' ? <strong>Best Of</strong> : ''}&nbsp;{startYear}
            {endYear !== '' ? `—${endYear}` : ''}
          </span>
        </header>
      )}
      <Accordion
        active={active}
        show={show || hideYear}
        className={styles.card_grid_collection__accordion}>
        <SpecialCardGrid
          type={type}
          cards={collectionEntries}
          features={features[type]}
          lazyloadImages={false}
        />
        {/* WIP awards module */}
        {get(awards, '[0].awardCards', []).length === 3 ? (
          <div className={styles.card_grid_collection__awards}>
            <AwardsModule data={awards[0]} />
          </div>
        ) : (
          ''
        )}
      </Accordion>
    </section>
  );
};

export default CardGridCollection;
