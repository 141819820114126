import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

// helpers
import { get } from 'helpers/utilities';
import classNames from 'classnames/bind';
import canUseDOM from 'helpers/canUseDOM';
import EventHandler from 'helpers/EventHandler';
import { getAssociatedTaxonomy } from 'queries/twm_components';

// components
import FixedBackground from 'components/FixedBackground';
import SentinelIntersect from 'components/SentinelIntersect';
import Icon from 'components/Icon';
import { useStickyFilter } from '../StickyFilter';

// hooks
import useReducedMotion from '../../hooks/useReducedMotion';
import useTimeout from '../../hooks/useTimeout';
import useNavigation from '../../hooks/useNavigation';
import useGlobalBackground from '../../hooks/useGlobalBackground';
import useOverlay from '../../hooks/useOverlay';
import useTaxonomies from '../../hooks/useTaxonomies';

import styles from './styles.scss';

const cx = classNames.bind(styles);

const ExpandedFilter = () => {
  const data = useTaxonomies();
  const globalBackground = useGlobalBackground();
  const { setNavVisible } = useNavigation();
  const { isReducedMotionActive } = useReducedMotion();
  const stickyFilter = useStickyFilter();
  const history = useHistory();
  const [entered, setEntered] = useState(false);
  const [out, setOut] = useState(false);
  const [showBackground, setShowBackground] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { setOverlay } = useOverlay();

  const timeout = useTimeout();
  const componentRef = useRef();

  const delayRedirect = useCallback(
    (event, to, i) => {
      event.preventDefault();
      setOut(true);
      setIsRedirecting(true);
      globalBackground.setCurrentIndex(i + 1);
      timeout(() => {
        window.scrollTo(0, 0);
        history.push(to);
        setNavVisible(true);

        setEntered(false);
        setOut(false);
        setIsRedirecting(false);
        setShowBackground(false);
      }, 1500);
    },
    [history.push, timeout, setOut, setIsRedirecting, setEntered, setShowBackground]
  );

  const mouseEnterHandler = useCallback(
    i => {
      globalBackground.setCurrentIndex(i + 1);
      if (!showBackground) {
        setShowBackground(true);
      }
    },
    [showBackground, setShowBackground]
  );

  const mouseLeaveHandler = useCallback(() => {
    if (!isRedirecting) {
      setShowBackground(false);
    }
  }, [isRedirecting, setShowBackground]);

  useEffect(() => {
    if (isReducedMotionActive) {
      setEntered(true);
    }
  }, []);

  useEffect(() => {
    let scrollObserver;
    if (canUseDOM && componentRef.current) {
      const option = { root: null, rootMargin: '0px', threshold: 0 };
      scrollObserver = new IntersectionObserver(e => {
        if (e[0].isIntersecting) {
          if (entered) return;
          if (
            data.length &&
            typeof globalBackground.currentIndex !== 'number' &&
            !globalBackground.assets.length
          ) {
            globalBackground.setCurrentIndex(0);
            globalBackground.setAssets(data);
          }

          setEntered(true);
        }
      }, option);

      scrollObserver.observe(componentRef.current);
    }

    return () => {
      if (scrollObserver) EventHandler.unsubscribe(scrollObserver);
    };
  }, [
    data,
    globalBackground.currentIndex,
    JSON.stringify(globalBackground.assets),
    setEntered,
    entered
  ]);

  const containerStyles = cx('expandedFilter__container', {
    'expandedFilter__container--entered': entered,
    'expandedFilter__container--entered-out': out
  });
  const parentStyles = cx('expandedFilter', {
    'expandedFilter--showBg': showBackground
  });

  if (!data.length) {
    return null;
  }

  return (
    <SentinelIntersect
      onLoad={false}
      config={{ threshold: [0, 1] }}
      onIntersection={intersected => stickyFilter.setStickyFilterIsInView(!intersected)}>
      <section className={parentStyles} ref={componentRef}>
        <div className={containerStyles}>
          {/*
                        @TODO: The background seems necessary for the functionality of this component.
                                Why was it not included in the component?
                    */}
          <FixedBackground />
          <div className="grid-container wrapper">
            <div className={styles.expandedFilter__titleArea}>
              <h2 className={styles.expandedFilter__title}>
                See the Work <span>We Make for </span>
                <span>—</span>
              </h2>
              <button
                className={styles.expandedFilter__search}
                onClick={() => setOverlay('search')}
                type="button">
                Search For Something Else
                <Icon name="search" color="white" size="small" />
              </button>
            </div>
            <ul className={styles.expandedFilter__list} onMouseLeave={mouseLeaveHandler}>
              {data.map((filter, i) => {
                const associatedTaxonomy = getAssociatedTaxonomy(get(filter, 'tagPageEntry[0]'));
                if (!associatedTaxonomy) return null;

                const { tagTitle, title } = associatedTaxonomy;
                return (
                  <li key={i} className={styles.expandedFilter__item}>
                    <Link
                      to={{ pathname: `/${get(filter, 'tagPageEntry[0].uri')}` }}
                      title={tagTitle || title}
                      onMouseEnter={() => mouseEnterHandler(i)}
                      onClick={e => delayRedirect(e, `/${get(filter, 'tagPageEntry[0].uri')}`, i)}>
                      {tagTitle || title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    </SentinelIntersect>
  );
};

export default ExpandedFilter;
