import React from 'react';

// componenets
import CarouselHeroDesktop from './CarouselHeroDesktop';
import CarouselHeroMobile from './CarouselHeroMobile';

// hooks
import useBreakpointKey, { SMALL_DESKTOP } from '../../hooks/useBreakpointKey';

const CarouselHero = props => {
  const { data } = props;
  const breakpointKey = useBreakpointKey();

  if (breakpointKey >= SMALL_DESKTOP) {
    return <CarouselHeroDesktop data={data} />;
  }

  return <CarouselHeroMobile data={data} />;
};

export default CarouselHero;
