import gsap from 'gsap';
// due to gsap upgrade from 2.0 to 3.0
// some functionality has been slice out
// https://greensock.com/forums/topic/22033-killchildtweensof-replacement/
export const killChildTweensOf = (parent, complete) => {
  const parents = gsap.utils.toArray(parent);
  let i = parents.length;
  const _isDescendant = function (element) {
    while (element) {
      element = element.parentNode;
      if (element === parent) {
        return true;
      }
    }
  };
  let j;
  let tweens;
  let targets;
  if (i > 1) {
    while (--i > -1) {
      killChildTweensOf(parents[i], complete);
    }
    return;
  }
  parent = parents[0];
  tweens = gsap.globalTimeline.getChildren(true, true, false);
  for (i = 0; i < tweens.length; i++) {
    targets = tweens[i].targets();
    j = targets.length;
    for (j = 0; j < targets.length; j++) {
      if (_isDescendant(targets[j])) {
        if (complete) {
          tweens[i].totalProgress(1);
        }
        tweens[i].kill();
      }
    }
  }
};
