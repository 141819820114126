import React, { Fragment, useState, useRef, useCallback } from 'react';
import { stripParagraphTag } from 'helpers/text';
import { get } from 'helpers/utilities';

import CardGridCollection from 'components/CardGridCollection';
import useBreakpoint from '../../hooks/useBreakpoint';
import useTimeout from '../../hooks/useTimeout';

import styles from './styles.scss';

const gridLayoutMap = {
  layout1: 'specialized1',
  layout2: 'specialized2',
  layout3: 'simplified'
};

const CardGrid = props => {
  const { cardGridTitle, data } = props;

  const timeout = useTimeout();
  const breakpoint = useBreakpoint();
  const [currentToggled, setCurrentToggled] = useState(0);
  const cardGridCollectionRefs = useRef(data.map(() => useRef()));

  const toggleHandler = useCallback(
    index => {
      if (currentToggled < index) {
        cardGridCollectionRefs.current[currentToggled].current.scrollIntoView();
      } else {
        cardGridCollectionRefs.current[currentToggled].current.scrollIntoView();
      }
      timeout(() => {
        cardGridCollectionRefs.current[index].current.scrollIntoView({ behavior: 'smooth' });
      }, 300);
      setCurrentToggled(index);
    },
    [currentToggled, setCurrentToggled, timeout]
  );

  const renderCardsContainer = useCallback(
    (cardGridData, index) => {
      const isMobile = breakpoint === 'mobile';
      const type = get(cardGridData, 'entriesYearGrid.gridLayout');
      return (
        <CardGridCollection
          type={gridLayoutMap[type] || 'specialized1'}
          active={isMobile}
          show={currentToggled === index}
          awardsModule={get(cardGridData, 'awardsModule')}
          {...get(cardGridData, 'entriesYearGrid')}
          cardGridCollectionRef={cardGridCollectionRefs.current[index]}
          onCollectionNameClick={() => toggleHandler(index)}
          className={styles.cardGrid__collection}
        />
      );
    },
    [breakpoint, currentToggled]
  );

  return (
    <section className={styles.cardGrid}>
      <h2 className={styles.cardGrid__titleContainer}>
        <span
          className={styles.cardGrid__title}
          dangerouslySetInnerHTML={{ __html: stripParagraphTag(cardGridTitle) }}
        />
      </h2>
      {data.map((cardGridData, i) => (
        <Fragment key={i}>{renderCardsContainer(cardGridData, i)}</Fragment>
      ))}
    </section>
  );
};

export default CardGrid;
