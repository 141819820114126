import { useQuery } from '@apollo/react-hooks';
import { get } from 'helpers/utilities';
import theThingsWeMake from 'queries/theThingsWeMake';
import { components as twmcomponents } from 'queries/twm_components';

const useAllWorks = () => {
  const query = theThingsWeMake.component(twmcomponents.allWork.component);

  const { data } = useQuery(query);
  const allWork = get(data, 'entry.allWorkCardGrid', null);

  return allWork;
};

export default useAllWorks;
