import React, { useEffect, useRef } from 'react';
import EventHandler from 'helpers/EventHandler';
import { EVENT } from 'config/constants';
import { getThrottledRAF } from 'helpers/layout';

import styles from './styles.scss';

const TRACK_PERCENTAGES = [0.25, 0.5, 0.75, 0.9];

const ScrollDepthTracker = ({ children, pageName, pageURL }) => {
  const topSentinelRef = useRef();
  const botSentinelRef = useRef();

  useEffect(() => {
    const throttledRAF = getThrottledRAF();
    const trackedPercentages = {};

    const scrollEvent = EventHandler.subscribe(EVENT.SCROLL, () =>
      throttledRAF(() => {
        const topSentinelBounding = topSentinelRef.current?.getBoundingClientRect();
        const botSentinelBounding = botSentinelRef.current?.getBoundingClientRect();

        if (!topSentinelBounding || !botSentinelBounding) {
          return;
        }

        const scrollDepthHeight = botSentinelBounding.top - topSentinelBounding.top;

        TRACK_PERCENTAGES.forEach(percentage => {
          if (
            !trackedPercentages[percentage] &&
            Math.abs(topSentinelBounding.top) > scrollDepthHeight * percentage
          ) {
            trackedPercentages[percentage] = true;
            window.dataLayer.push({
              event: 'scrollDepthPageTrack',
              pageName,
              pageURL,
              percentage
            });
          }
        });
      })
    );

    return () => EventHandler.unsubscribe(scrollEvent);
  }, []);

  return (
    <>
      <div className={styles.scrollDepthTracker} ref={topSentinelRef} />
      {children}
      <div className={styles.scrollDepthTracker} ref={botSentinelRef} />
    </>
  );
};

export default ScrollDepthTracker;
